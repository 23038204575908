import Lenis from "@studio-freight/lenis";
import $ from "jquery";
import Flip from "gsap/Flip";
import SimpleLightbox from "simplelightbox";
import { Loader } from "@googlemaps/js-api-loader";
import { gsap, ScrollTrigger, Draggable, MotionPathPlugin } from "gsap/all";
import Swiper from "swiper";
import hideOnScroll from "hide-on-scroll";
import ready from "@document/ready";
import Swiper from "swiper";

import Swiper, {
  Navigation,
  Pagination,
  Autoplay,
  EffectFade,
  EffectCoverflow,
  Parallax,
  EffectCards,
} from "swiper";
import { EffectCoverflow } from "swiper";

Swiper.use([
  Navigation,
  Pagination,
  Autoplay,
  EffectFade,
  Parallax,
  EffectCoverflow,
]);
gsap.registerPlugin(ScrollTrigger, Draggable, Flip, MotionPathPlugin);

const singleSwipe = new Swiper(".singleSwipe", {
  // loop: true,
  speed: 800,
  slidesPerView: "1",
  grabCursor: true,
  effect: "fade",

  followFinger: false,
  autoplay: {
    delay: 2000,
    effect: "slide",
    disableOnInteraction: false,
  },
  on: {
    init: function () {
      asignLinks();
    },
  },
});
function asignLinks() {
  const itemBox = [...document.querySelectorAll(".swiperLink")];

  itemBox.forEach((el) => {
    el.addEventListener("mouseover", function () {
      singleSwipe.slideTo(el.dataset.slide);
    });

    el.addEventListener("click", function () {
      event.preventDefault();
      $("body").addClass("ofloaded");
      $("body").removeClass("loaded");

      var linkLocation = el.getAttribute("href");
      console.log(linkLocation);

      setTimeout(function () {
        window.location = linkLocation;
      }, 800);
    });

    el.addEventListener("touchstart", function () {
      event.preventDefault();
      $("body").addClass("ofloaded");
      $("body").removeClass("loaded");

      var linkLocation = el.getAttribute("href");
      console.log(linkLocation);

      setTimeout(function () {
        window.location = linkLocation;
      }, 800);
    });
  });
}

const menuLink = [...document.querySelectorAll(".menuTop .menu a")];

menuLink.forEach((el) => {
  el.addEventListener("click", function () {
    event.preventDefault();
    $("body").addClass("ofloaded");
    $("body").removeClass("loaded");

    var linkLocation = el.getAttribute("href");
    console.log(linkLocation);

    setTimeout(function () {
      window.location = linkLocation;
    }, 800);
  });
});

const headerLink = [...document.querySelectorAll(".logoPorte a")];

headerLink.forEach((el) => {
  el.addEventListener("click", function () {
    event.preventDefault();
    $("body").addClass("ofloaded");
    $("body").removeClass("loaded");

    var linkLocation = "/";
    console.log(linkLocation);

    setTimeout(function () {
      window.location = linkLocation;
    }, 800);
  });
});

const lenis = new Lenis({
  duration: 1.2,
  easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)), // https://www.desmos.com/calculator/brs54l4xou
  direction: "vertical", // vertical, horizontal
  gestureDirection: "vertical", // vertical, horizontal, both
  smooth: true,
  mouseMultiplier: 1,
  smoothTouch: false,
  touchMultiplier: 2,
  infinite: false,
});

const scrollFn = () => {
  lenis.raf();
  requestAnimationFrame(scrollFn);
};

// const homepageSlider = [...document.querySelectorAll(".homepageSlider")];

// homepageSlider.forEach((el) => {
//   gsap.set(el, {
//     opacity: 0,
//   });
//   gsap.to(el, {
//     scrollTrigger: {
//       trigger: el,
//     },
//     ease: "ExpoScaleEase",

//     scale: 1,
//     delay: 0.1,
//     opacity: 1,
//     duration: 12.2,
//   });
// });

const biossun = [...document.querySelectorAll(".biossun img")];

biossun.forEach((el) => {
  gsap.set(el, {
    scale: 1.1,
  });
  gsap
    .timeline({
      scrollTrigger: {
        trigger: el,
        start: "top bottom",
        end: "center center",

        scrub: true,
      },
    })
    .to(el, {
      scale: 1,

      ease: "ExpoScaleEase",
      stagger: {
        dutation: 1500,
        repeat: 0,
      },
    });
});

const imgHolder = [...document.querySelectorAll(".imgHolder.page img")];

imgHolder.forEach((el) => {
  gsap
    .timeline({
      scrollTrigger: {
        trigger: el,
        start: "top top",
        end: "bottom top",

        scrub: true,
      },
    })
    .to(el, {
      scale: 1.12,

      ease: "ExpoScaleEase",
      stagger: {
        dutation: 1500,
        repeat: 0,
      },
    });
});

const downloadImage = [...document.querySelectorAll(".downloadImage")];

downloadImage.forEach((el) => {
  gsap.set(el, {
    scale: 0.85,
  });
  gsap
    .timeline({
      scrollTrigger: {
        trigger: el,
        start: "top bottom",
        end: "cennter center",

        scrub: true,
      },
    })
    .to(el, {
      scale: 1,

      ease: "ExpoScaleEase",
      stagger: {
        dutation: 1500,
        repeat: 0,
      },
    });
});

const scrollZoom = [...document.querySelectorAll(".zoomImg img")];

scrollZoom.forEach((el) => {
  gsap.set(el, {
    scale: 1.1,
  });
  gsap
    .timeline({
      scrollTrigger: {
        trigger: el,
        start: "top bottom",
        end: "top top",
        scrub: true,
      },
    })
    .to(el, {
      scale: 1.0,

      ease: "ExpoScaleEase",
      stagger: {
        dutation: 1500,
        repeat: 0,
      },
    });
});

requestAnimationFrame(scrollFn);

hideOnScroll({
  navbarSelector: ".header",
  hidingClass: "scroll",
  pollingInterval: 10,
});

$(document).ready(function () {
  // $(".burger-menu").click(function () {
  //   var myBox = $(".navigace");
  //   var myBox2 = $(".navItems");

  //   if ($(this).hasClass("menu-on")) {
  //     myBox2.removeClass("show");
  //     $(this).toggleClass("menu-on");
  //     $(".navItems").removeClass("show");
  //     $(".kozichFloat").toggleClass("side");

  //     myBox2.one(
  //       "webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend",
  //       function (event) {
  //         $(".navigace").removeClass("show");
  //       }
  //     );
  //   } else {
  //     $(this).toggleClass("menu-on");

  //     $(".kozichFloat").toggleClass("side");
  //     $(".navigace").toggleClass("show");

  //     myBox.addClass("show");
  //     myBox.one(
  //       "webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend",
  //       function (event) {
  //         $(".navItems").addClass("show");
  //       }
  //     );
  //   }
  // });

  document.addEventListener("touchstart", function () {}, true);
});

/* PĹ™echody strĂˇnek  -------------------------------------------------------------------------------------------------------------*/

/************************************************* page WHEN LOAD ANIMATION ******************************************/
downloadImage.onload = function () {};
ready(() => {
  $("body").addClass("loaded");
});
/************************************************* page WHEN LOAD ANIMATION ******************************************/

async function showMapa() {
  const loader = new Loader({
    apiKey: "AIzaSyCRxzmj4eleUyOyQLlz2j7FLxdXYQxmMKw",
    version: "weekly",
  });

  const myLatLng = { lat: 49.1583214, lng: 16.63699 };

  const mapOptions = {
    center: myLatLng,
    zoom: 12,
    scrollwheel: false,
    styles: [
      {
        featureType: "administrative",
        elementType: "all",
        stylers: [
          {
            saturation: "-100",
          },
        ],
      },
      {
        featureType: "administrative.province",
        elementType: "all",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "landscape",
        elementType: "all",
        stylers: [
          {
            saturation: -100,
          },
          {
            lightness: 65,
          },
          {
            visibility: "on",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "all",
        stylers: [
          {
            saturation: -100,
          },
          {
            lightness: "50",
          },
          {
            visibility: "simplified",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "all",
        stylers: [
          {
            saturation: "-100",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "all",
        stylers: [
          {
            visibility: "simplified",
          },
        ],
      },
      {
        featureType: "road.arterial",
        elementType: "all",
        stylers: [
          {
            lightness: "30",
          },
        ],
      },
      {
        featureType: "road.local",
        elementType: "all",
        stylers: [
          {
            lightness: "40",
          },
        ],
      },
      {
        featureType: "transit",
        elementType: "all",
        stylers: [
          {
            saturation: -100,
          },
          {
            visibility: "simplified",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [
          {
            hue: "#ffff00",
          },
          {
            lightness: -25,
          },
          {
            saturation: -97,
          },
        ],
      },
      {
        featureType: "water",
        elementType: "labels",
        stylers: [
          {
            lightness: -25,
          },
          {
            saturation: -100,
          },
        ],
      },
    ],
    zoom: 12,
    scrollwheel: false,
    mapTypeControl: false,
    streetViewControl: false,
    scaleControl: true,
    rotateControl: false,
  };
  const image = "files/img/porteMapa.png";
  loader.load().then(function (google) {
    const svgMarker = {
      path:
        "M5 8.97266C7.25 8.72363 9 6.81641 9 4.5C9 2.01465 6.98535 0 4.5 0C2.01465 0 0 2.01465 0 4.5C0 6.81641 1.75 8.72363 4 8.97266V23H5V8.97266Z",
      fillColor: "#07211f",
      fillOpacity: 1,
      strokeWeight: 0,
      rotation: 0,
      scale: 3,
      anchor: new google.maps.Point(9, 23),
    };

    const map = new google.maps.Map(document.getElementById("map"), mapOptions);

    new google.maps.Marker({
      position: myLatLng,
      map,
      icon: svgMarker,
      title: "Porte Karpi",
    });
  });
}

const promis = document.getElementById("map");

if (promis) {
  showMapa();
}
// const real = [...document.querySelectorAll(".grid-wrapper div")];

// real.forEach((el) => {
//   gsap.set(el, {
//     opacity: 0,
//   });
//   gsap
//     .timeline({
//       scrollTrigger: {
//         trigger: el,
//         start: "top bottom",
//         end: "center center",

//         scrub: true,
//       },
//     })
//     .to(el, {
//       opacity: 1,

//       ease: "ExpoScaleEase",
//       stagger: {
//         dutation: 1500,
//         repeat: 0,
//       },
//     });
// });
